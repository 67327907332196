.root {
  display: grid;
  gap: 0.375rem;
  place-content: start stretch;
}
.activities {
  display: flex;
  gap: 2px;
  width: 100%;
}
.activity {
  --_value: var(--value, 33.33%);
  display: grid;
  gap: 0.25rem;
  flex: 1 1 var(--_value);
}

.activity::before {
  --_color: var(--color, rgb(255 255 255 / 30%));
  content: '';
  background: var(--_color);
  display: block;
  height: 0.375rem;
  width: 100%;
}
.activity[data-activity='none']::before {
  background: rgb(255 255 255 / 10%);
}

/* .activity[data-activity="PICKING"]::before {
  --_color: var(--chakra-colors-zest-800);
}
.activity[data-activity="STORING"]::before {
  --_color: #CA3214;
}
.activity[data-activity="INSPECTION"]::before {
  --_color: #147D6F;
}
.activity[data-activity="CONFIG"]::before {
  --_color: #9C2BAD;
}
.activity[data-activity="INSERT"]::before {
  --_color: #3E63DD;
}
.activity[data-activity="TRANSFER"]::before {
  --_color: #AD5700;
}
.activity[data-activity="PICK_FOR_CONSOLIDATION"]::before {
  --_color: #79818D;
} */

.activity:first-child::before {
  border-top-left-radius: 0.125rem;
  border-bottom-left-radius: 0.125rem;
}
.activity:last-child::before {
  border-top-right-radius: 0.125rem;
  border-bottom-right-radius: 0.125rem;
}

.status.inactive .currentActivity {
  color: var(--chakra-colors-navy-700);
}

.status {
  color: var(--chakra-colors-navy-300);
  font: normal 0.875rem/1 'Akkurat';
  letter-spacing: -0.02em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.stubVersion {
  border-radius: 2px;
  color: var(--chakra-colors-navy-500);
  font: normal 0.875rem/1 'Akkurat Mono';
}

.type {
  color: var(--chakra-colors-navy-400);
}
