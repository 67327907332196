.panel {
  border-radius: 0.25rem;
  padding: 1.5rem;
}

[data-theme='dark'] .panel {
  background: linear-gradient(
    color-mix(
      in srgb,
      var(--chakra-colors-navy-1000) 50%,
      var(--chakra-colors-navy-900)
    ),
    var(--chakra-colors-navy-1000)
  );
  box-shadow:
    inset 0px 1px rgb(255 255 255 / 15%),
    0px 1px rgb(0 0 0 / 20%);
  color: var(--chakra-colors-navy-300);

  .heading {
    color: var(--chakra-colors-navy-100);
  }
}

[data-theme='light'] .panel {
  background: var(--chakra-colors-gray-100);
  box-shadow: 0px 1px var(--chakra-colors-gray-500);
  color: var(--chakra-colors-navy-800);
  .heading {
    color: var(--chakra-colors-navy-900);
  }
}
