.org {
  padding: 0.75rem;
  grid-template-rows: min-content auto min-content;
  gap: 0.75rem;
}

.orgStatus {
  display: grid;
  gap: 2px;
  place-items: center;
}

.org.statusDisconnected {
  background: linear-gradient(
    var(--chakra-colors-red-800),
    var(--chakra-colors-red-900)
  );

  .orgName,
  .statusIcon,
  [class*='stubVersion'] {
    color: var(--chakra-colors-red-100);
  }
}

.org.statusServiceMode {
  background: linear-gradient(
    var(--chakra-colors-yellow-500),
    var(--chakra-colors-yellow-600)
  );
  --metric-icon-color: var(--chakra-colors-yellow-200);
  --metric-label-color: var(--chakra-colors-yellow-100);
  opacity: 0.9;
  .orgName,
  .statusIcon,
  [class*='stubVersion'] {
    color: var(--chakra-colors-yellow-100);
  }
}

.org[class*='status'] {
  .orgName {
    opacity: 0.9;
  }
  [class*='currentActivity'] {
    color: rgb(255 255 255 / 10%) !important;
  }
  .metrics {
    opacity: 0.4;
    filter: grayscale(1);
  }
}

.metric.inactive {
  opacity: 0.75;
}
.metric {
  background: color-mix(
    in oklch,
    var(--chakra-colors-navy-1200) 10%,
    transparent
  );
  border-radius: 0.25rem;
  box-shadow:
    0px 2px 4px inset rgb(0 0 0 / 25%),
    0px 1px 0px rgb(255 255 255 / 10%);
  padding: 0.5rem;
  gap: 0.25rem;
  align-items: center;
}
.metricLabel {
  --_color: var(--metric-label-color, var(--chakra-colors-navy-300));
  color: var(--_color);
  font: normal 1.125rem/1 'Akkurat Mono';
  min-width: 5ch;
}
.metricIcon {
  color: var(--metric-icon-color, var(--chakra-colors-navy-400));
}

.legend {
  place-self: end;
  flex-wrap: wrap;
  column-gap: 0.75rem;
  row-gap: 0.25rem;
  color: var(--chakra-colors-navy-500);
}

.statusIcon {
  color: var(--chakra-colors-navy-400);
  flex: 0 0 auto;
}
