.lucide {
  stroke-width: 1.5px;
}

:root[data-theme][data-theme='light'] {
  --chakra-colors-chakra-body-text: var(--chakra-colors-navy-800);
  --chakra-colors-chakra-body-bg: var(--chakra-colors-white);
  --chakra-colors-chakra-border-color: var(--chakra-colors-gray-500);
  --chakra-colors-chakra-inverse-text: var(--chakra-colors-white);
  --chakra-colors-chakra-subtle-bg: var(--chakra-colors-gray-200);
  --chakra-colors-chakra-subtle-text: var(--chakra-colors-navy-600);
  --chakra-colors-chakra-placeholder-color: var(--chakra-colors-navy-600);

  body {
    background: var(--chakra-colors-gray-300);
  }
}

:root[data-theme][data-theme='dark'] {
  body {
    background: var(--chakra-colors-navy-1100);
  }
}
