dl.logMeta {
  display: flex;
  align-items: center;
  line-height: 1.5;
  overflow-x: auto;
  margin-block-end: 0.5rem;

  dt {
    text-transform: uppercase;
    font-size: 80%;
    font-weight: bold;
    color: var(--chakra-colors-navy-600);
    line-height: 1;
    white-space: nowrap;
  }

  dd {
    color: var(--chakra-colors-teal-500);
    margin-inline: 0.5rem 2rem;
    line-height: inherit;
    font-weight: bold;
    white-space: nowrap;
  }
}

.logTable {
  th {
    border-color: var(--chakra-colors-gray-400);
    color: var(--chakra-colors-navy-600);
    padding: 0.5rem 0;
  }
  td {
    border: none;
    border-bottom: 1px solid var(--chakra-colors-gray-400);
    color: var(--chakra-colors-navy-800);
    padding: 0.5rem 0;
  }
  tbody:last-of-type tr:last-child td {
    border-bottom: none;
  }
}

.filters {
  gap: 1rem;
  flex-wrap: wrap;

  label span {
    display: block;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 0.06em;
    line-height: 1;
    margin-block-end: 0.5em;
    color: var(--chakra-colors-navy-800);
  }
  input {
    width: auto;
  }
  button {
    margin-block-start: 21px;
  }
}

[data-theme='dark'] {
  .filters label span {
    color: var(--chakra-colors-navy-300);
  }

  dl.logMeta {
    dt {
      color: var(--chakra-colors-navy-400);
    }
    dd {
      color: var(--chakra-colors-zest-300);
    }
  }

  .logTable {
    th {
      border-color: var(--chakra-colors-navy-900);
      color: var(--chakra-colors-navy-400);
    }

    td {
      color: var(--chakra-colors-navy-100);
      border-color: var(--chakra-colors-navy-900);
    }
  }
}
