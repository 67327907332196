.root {
  --_width: var(--width, 50%);
  --_color: var(--color, #fff);
  background: color-mix(
    in oklch,
    var(--chakra-colors-navy-1200) 10%,
    transparent
  );
  border-radius: 0.25rem;
  box-shadow:
    0px 2px 4px inset rgb(0 0 0 / 25%),
    0px 1px 0px 0px rgb(255 255 255 / 10%);
  padding: 0.5rem;
  display: grid;
  gap: 0.25rem;
  flex: 1;
  grid-area: 1/3/3/4;
  position: relative;
}

.bar {
  background: var(--chakra-colors-navy-1000);
  border-radius: 1rem;
  height: 1rem;
  box-shadow:
    0px 6px 2px -4px inset var(--chakra-colors-navy-1100),
    0px 1px 0px 0px rgb(255 255 255 / 5%);
  width: 100%;
  overflow: clip;
  position: relative;
}

.bar::before {
  content: '';
  background: transparent;
  box-shadow:
    inset 0 2px 4px 0px rgb(255 255 255 / 35%),
    inset 0 -2px 6px rgb(0 0 0 / 15%);
  border-radius: 1rem;
  display: block;
  width: calc(100% - 2px);
  position: absolute;
  inset: 1px;
  z-index: 1;
}

.bar::after {
  content: '';
  background: var(--_color);
  box-shadow:
    inset 0 2px 6px 0px rgb(0 0 0 / 15%),
    inset 0 -2px 6px rgb(0 0 0 / 15%);
  border-radius: 1rem;
  display: block;
  width: calc(min(100%, var(--_width)) - 2px);
  position: absolute;
  inset: 1px;
  transition: all 0.5s ease-in;
}

.speed {
  color: #fff;
  font: bold 2rem/1 'Akkurat Mono';
  flex: 1;
  display: grid;
}
.speed.inactive {
  font-weight: normal;
  opacity: 0.5;
}

.speed::before {
  content: 'OL/H';
  color: var(--chakra-colors-navy-500);
  font: normal 0.75rem/1 'Akkurat Mono';
}

.bar.godlike::after {
  background-image: linear-gradient(
    to left,
    #793aaf,
    #3e63dd,
    #3fd993,
    #edfaa3,
    #3fd993,
    #3e63dd,
    #793aaf
  );
  background-size: 400% 400%;
  animation: gradient 3s ease-in-out infinite;
  border-radius: 1rem;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
