.panels {
  grid-template-columns: repeat(auto-fit, minmax(min(500px, 100%), 1fr));
  gap: 0.25rem;
  padding: 0.25rem;
  min-height: 100vh;
}

.panel {
  --panel-padding: 0.75rem;
  padding: var(--panel-padding);
}

.panelHeading {
  color: var(--chakra-colors-zestBright-200);
  font: normal clamp(1rem, 0.6374rem + 1.547vw, 1.875rem) / 1 'Akkurat';
  text-transform: uppercase;
  position: absolute;
  top: 1rem;
  left: 1rem;
}

.panelLargeNumber {
  color: white;
  font: normal clamp(3rem, -0.1667rem + 13.5111cqi, 12.5rem) / 1 'Akkurat Mono';
  padding: calc(var(--panel-padding) * 2);
  word-spacing: -0.25ch;
}

.scoremeter {
  --_width: var(--width, 50%);
  --_color: var(--color, green);
  background: var(--chakra-colors-navy-1000);
  border-radius: 1rem;
  height: 1rem;
  box-shadow:
    0px 6px 2px -4px inset var(--chakra-colors-navy-1100),
    0px 1px 0px 0px rgb(255 255 255 / 5%);
  width: 100%;
  overflow: clip;
  position: relative;
}

.scoremeter::before {
  content: '';
  background: transparent;
  box-shadow:
    inset 0 2px 4px 0px rgb(255 255 255 / 20%),
    inset 0 -2px 6px rgb(0 0 0 / 15%);
  border-radius: 1rem;
  display: block;
  width: calc(100% - 2px);
  position: absolute;
  inset: 1px;
  z-index: 1;
}

.scoremeter::after {
  content: '';
  background: var(--_color);
  box-shadow:
    inset 0 2px 6px 0px rgb(0 0 0 / 15%),
    inset 0 -2px 6px rgb(0 0 0 / 15%);
  border-radius: 1rem;
  display: block;
  width: calc(min(100%, var(--_width)) - 2px);
  position: absolute;
  inset: 1px;
  transition: all 0.5s ease-in;
}

.scoremeter.godlike::before {
  background-image: linear-gradient(
    to left,
    #793aaf,
    #3e63dd,
    #3fd993,
    #edfaa3,
    #3fd993,
    #3e63dd,
    #793aaf
  );
  background-size: 400% 400%;
  animation: gradient 3s ease-in-out infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
